import dynamic from "next/dynamic";

export const components = {
  HeroCardSection: dynamic(() =>
    import("./Home/HeroCardSection").then((x) => x.HeroCardSection),
  ),
  /** plop marker - do not touch */
  SectionImageGallery: dynamic(
    () => import("./Harmony/ImageGallery/SectionImageGallery"),
  ),
  SectionHorizontalAccordion: dynamic(
    () => import("./Harmony/HorizontalAccordion/SectionHorizontalAccordion"),
  ),
  SectionCenteredHero: dynamic(
    () => import("./Harmony/Hero/SectionCenteredHero"),
  ),
  SectionContentImage: dynamic(
    () => import("./Harmony/ContentImage/SectionContentImage"),
  ),
  SectionUseCases: dynamic(() => import("./Harmony/UseCases/SectionUseCases")),
  SectionB2BReviews: dynamic(
    () => import("./Harmony/Reviews/SectionB2BReviews"),
  ),
  SectionIllustrationSeparator: dynamic(
    () =>
      import("./Harmony/IllustrationSeparator/SectionIllustrationSeparator"),
  ),
  SectionProductHiw: dynamic(
    () => import("./Harmony/ProductHIW/SectionProductHiw"),
  ),
  SectionVideoCarousel: dynamic(
    () => import("./Harmony/VideoGallery/SectionVideoCarousel"),
  ),
  SectionProductScroll: dynamic(
    () => import("./Harmony/ProductScroll/SectionProductScroll"),
  ),
  SectionHowItWorksNarrow: dynamic(
    () => import("./Harmony/HowItWorks/SectionHowItWorksNarrow"),
  ),
  SectionLogosMarquee: dynamic(
    () => import("./Harmony/Logos/SectionLogosMarquee"),
  ),
  SectionNavigationCards: dynamic(
    () => import("./Harmony/NavigationCards/SectionNavigationCards"),
  ),
  SectionBookDemo: dynamic(() => import("./Harmony/BookDemo/BookDemoSection")),
  SectionIframe: dynamic(() => import("./Harmony/Iframe/SectionIframe")),

  SectionNewswireAnnouncements: dynamic(
    () =>
      import("./Harmony/NewswireAnnouncements/SectionNewswireAnnouncements"),
  ),
  SectionNewsletterNarrow: dynamic(
    () => import("./Harmony/Newsletter/SectionNewsletterNarrow"),
  ),
  SectionBlogHero: dynamic(() => import("./Harmony/Blog/SectionBlogHero")),

  SectionPressHighlights: dynamic(
    () => import("./Harmony/PressHighlights/SectionPressHighlights"),
  ),
  SectionBlogList: dynamic(() => import("./Harmony/Blog/SectionBlogList")),
  SectionVideoGallery: dynamic(
    () => import("./Harmony/VideoGallery/SectionVideoGallery"),
  ),
  SectionStatementText: dynamic(
    () => import("./Harmony/Content/SectionStatementText"),
  ),
  SectionCareStoriesHero: dynamic(
    () => import("./Harmony/CareStories/SectionCareStoriesHero"),
  ),
  SectionPositionsList: dynamic(
    () => import("./Harmony/Careers/SectionPositionsList"),
  ),
  SectionPartnerTestimonialVideo: dynamic(
    () => import("./Harmony/PartnerTestimonials/SectionPartnerTestimonial"),
  ),
  SectionTestimonialVideoCards: dynamic(
    () => import("./Harmony/VideoGallery/SectionTestimonialVideoCards"),
  ),
  SectionSimpleHeader: dynamic(
    () => import("./Harmony/SimpleHeader/SectionSimpleHeader"),
  ),
  SectionDisclaimerFooter: dynamic(
    () => import("./Harmony/DisclaimerFooter/SectionDisclaimerFooter"),
  ),
  SectionPartnerFaq: dynamic(
    () => import("./Harmony/PartnerFaq/SectionPartnerFaq"),
  ),
  SectionResourceList: dynamic(
    () => import("./Harmony/ResourceList/SectionEmployerResourceList"),
  ),
  SectionNewsletter: dynamic(
    () => import("./Harmony/Newsletter/SectionNewsletter"),
  ),
  SectionWebinarPost: dynamic(
    () => import("./Harmony/Webinar/SectionGatedContentForm"),
  ),
  SectionVimeoWebinar: dynamic(
    () => import("./Harmony/Webinar/SectionVimeoWebinar"),
  ),
  SectionSpeakers: dynamic(() => import("./Harmony/Speakers/SectionSpeakers")),
  SectionProductTabs: dynamic(
    () => import("./Harmony/ProductTabs/SectionProductTabs"),
  ),
  SectionCareStoriesCarousel: dynamic(
    () => import("./Harmony/CareStories/SectionCareStoriesCarousel"),
  ),
  SectionImplementationCard: dynamic(
    () => import("./Harmony/Implementation/SectionImplementationCard"),
  ),
  SectionQuoteCard: dynamic(() => import("./Harmony/Quote/SectionQuoteCard")),
  SectionQuote: dynamic(() => import("./Harmony/Quote/SectionQuote")),
  SectionHubStateList: dynamic(
    () => import("./Harmony/HubSpoke/SectionHubStateList"),
  ),
  SectionHero: dynamic(() => import("./Harmony/Hero/SectionHero")),
  SectionHeroCard: dynamic(() => import("./Harmony/Hero/SectionHeroCard")),
  SectionContentTwoColumns: dynamic(() =>
    import("./Harmony/Content/SectionContentTwoColumns").then(
      (x) => x.SectionContentTwoColumns,
    ),
  ),
  SectionLogos: dynamic(() => import("./Harmony/Logos/SectionLogos")),
  SectionValuePropLines: dynamic(
    () => import("./Harmony/ValuePropLines/SectionValuePropLines"),
  ),
  SectionUserReviews: dynamic(() => import("./Harmony/Reviews/SectionReviews")),
  SectionContent: dynamic(() => import("./Harmony/Content/SectionContent")),
  SectionContentCard: dynamic(
    () => import("./Harmony/Content/SectionContentCard"),
  ),
  SectionLinkList: dynamic(() => import("./Harmony/LinkList/SectionLinkList")),
  SectionFaq: dynamic(() => import("./Harmony/FAQ/SectionFAQ")),
  SectionVideo: dynamic(() => import("./VideoSection/VideoSection")),
  SectionResourcesCards: dynamic(
    () => import("./Harmony/Resources/SectionResourceCards"),
  ),
  SectionResourcesImages: dynamic(
    () => import("./Harmony/Resources/SectionResourcesImages"),
  ),
  SectionValuePropCards: dynamic(
    () => import("./Harmony/ValuePropCards/SectionValuePropCards"),
  ),
  SectionStatistics: dynamic(
    () => import("./Harmony/Statistics/SectionStatistics"),
  ),
  SectionPartnerHeader: dynamic(() => import("./Partner/PartnerHeader")),
  SectionPartnerProduct: dynamic(
    () => import("./Partner/PartnerProductSection"),
  ),
  SectionPartnerTestimonials: dynamic(
    () => import("./Partner/PartnerTestimonials"),
  ),
  SectionPartnerFooter: dynamic(() => import("./Partner/PartnerFooter")),
  SectionPartnerFooterSimple: dynamic(
    () => import("./Partner/PartnerFooterSimple"),
  ),
  SectionPartnerContact: dynamic(() => import("./Partner/PartnerContact")),

  ProductScreens: dynamic(() =>
    import("./ProductScreens/ProductScreensSections").then(
      (x) => x.ProductScreensSection,
    ),
  ),
  SimpleCardSection: dynamic(
    () => import("./SimpleCardSection/SimpleCardSection"),
  ),
  UserReviewSection: dynamic(() => import("./Home/TestimonialsNew")),
  FeaturedCardsSection: dynamic(
    () => import("./FeaturedCards/FeaturedCardsSection"),
  ),
  VideoSection: dynamic(() => import("./VideoSection/VideoSection")),
  PublishersSection: dynamic(() => import("./Publishers/PublishersSection")),
  Spacer: dynamic(() => import("./sections/SectionSpacer")),
  ForFamiliesSection: dynamic(() => import("./Families/ForFamiliesSection")),
  FaqSection: dynamic(() => import("./FAQSection")),
  ValueCardsSection: dynamic(
    () => import("./Harmony/ValuePropCards/SectionValuePropCards"),
  ),
  CareStoriesSections: dynamic(
    () => import("./StorySlider/StorySliderSection"),
  ),
  StatisticsSection: dynamic(() => import("./Statistics/StatisticsSection")),
  PartnerProductSection: dynamic(
    () => import("./Partner/PartnerProductSection"),
  ),
  PartnerTestimonialsNewSection: dynamic(
    () => import("./Partner/PartnerTestimonials"),
  ),
  PartnerHeaderSection: dynamic(() => import("./Partner/PartnerHeader")),
  GoToPartnerSection: dynamic(() => import("./Partner/GoToPartner")),
  PartnerFooterSection: dynamic(() => import("./Partner/PartnerFooter")),
  PartnerFooterSimpleSection: dynamic(
    () => import("./Partner/PartnerFooterSimple"),
  ),
  PartnerContactSection: dynamic(() => import("./Partner/PartnerContact")),
  Seo: dynamic(() => import("next-seo").then((x) => x.NextSeo)),
} as const;

export const getComponent = (type: string) => {
  return components[type];
};
